import { required } from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import fmsProjMaster from '../../../../fms/unitInventory/fmsProject';
export default {
  name: 'addEditFmsInterestRate',
  components: { DatePicker, fmsProjMaster },
  props: ['intRateHdrId'],
  data() {
    return {
      showFmsPrjModal: false,
      uomClassObj: null,
      interestDtlIndex: null,
      parent_value_set_id: null,
      showValueSetModal: false,
      vsetCode: null,
      unsubscribe: null,
      editMode: true,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      loader: false,
      selectedFmsProject: {
        id: null,
        name: null
      },
      approvalStatus: {
        code: null,
        name: 'DRAFT'
      },
      fmsCompGroup: {
        id: null,
        name: null
      },
      interestForm: {
        fms_comp_group_id: null,
        fms_prj_id: null,
        interest_rate_hdr_id: 0,
        version: 0
      },

      detaliList: [
        {
          percentage_rate: null,
          interest_rate_dtl_id: 0,
          delay_days_from: null,
          frequency_uom_vset: null,
          interest_type_vset: null,
          frequency_uom: null,
          interest_type: null,
          delay_days_to: null,
          start_date: null,
          start_date_end_date: null,
          frequency: null,
          end_date: null
        }
      ],
      detailFeilds: [
        {
          key: 'interest_type'
        },
        {
          key: 'percentage_rate',
          label: 'rate'
        },
        {
          key: 'delay_days_from'
        },
        {
          key: 'delay_days_to'
        },
        {
          key: 'frequency'
        },
        {
          key: 'frequency_uom',
          label: 'UOM'
        },
        {
          key: 'start_date_end_date',
          label: 'Start Date - End Date'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  validations: {
    selectedFmsProject: {
      name: {
        required
      }
    }
  },
  mounted() {
    if (this.intRateHdrId) {
      this.getFmsInterestRateData(this.intRateHdrId);
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditFmsInterestRate();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    addEditFmsInterestRate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loader = true;
        const filterDetail = this.detaliList.filter(
          data => data.interest_type !== null
        );
        const interestDtl = filterDetail.map(data => {
          if (data.interest_type_vset === 'SI') {
            return {
              delay_days_from: data.delay_days_from,
              delay_days_to: data.delay_days_to,
              interest_rate_dtl_id: data.interest_rate_dtl_id,
              interest_type_vset: data.interest_type_vset,
              percentage_rate: data.percentage_rate,
              start_date: commonHelper.formattedDate(data.start_date_end_date[0]),
              end_date: commonHelper.formattedDate(data.start_date_end_date[1]),
            };
          } else {
            return {
              delay_days_from: data.delay_days_from,
              delay_days_to: data.delay_days_to,
              frequency: data.frequency,
              frequency_uom_vset: data.frequency_uom_vset,
              interest_rate_dtl_id: data.interest_rate_dtl_id,
              interest_type_vset: data.interest_type_vset,
              percentage_rate: data.percentage_rate,
              start_date: commonHelper.formattedDate(data.start_date_end_date[0]),
              end_date: commonHelper.formattedDate(data.start_date_end_date[1]),
            };
          }
        });

        const payload = {
          fms_comp_group_id: this.interestForm.fms_comp_group_id,
          fms_prj_id: this.interestForm.fms_prj_id,
          interest_rate_hdr_id: this.interestForm.interest_rate_hdr_id,
          version: this.interestForm.version,
          interest_rate_details: interestDtl
        };
        this.$store
          .dispatch('masterData/addEditFmsInterestRate', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.$emit('updateList');
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
            } else {
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getFmsInterestRateData(intRateHdrId) {
      this.loader = true;
      this.$store
        .dispatch('masterData/getInterestRateById', intRateHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            const resp = response.data.data;
            this.interestForm = resp;
            this.selectedFmsProject = {
              id: resp.fms_prj_id,
              name: resp.fms_prj_name
            };
            this.fmsCompGroup.id = resp.fms_comp_group_id;
            this.fmsCompGroup.name = resp.fms_comp_group_name;
            this.detaliList = resp.interest_rate_details.map(data => {
                data.start_date_end_date = [new Date(data.start_date), new Date(data.end_date)];
                return data;
              });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    opendDependentUom(vsetCode, index) {
      const payload = {
        valueSetName: vsetCode,
        dependentVariable: {
          user_id: this.$store.state.auth.userId,
          res_id: this.$store.state.shared.responsibilityId
        }
      };
      this.loader = true;
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          this.uomClassObj = null;
          if (response.status === 200) {
            const findTimeObj = response.data.data.page.find(
              vsetData => vsetData.value_code === 'TIME'
            );
            if (findTimeObj) {
              this.uomClassObj = findTimeObj;
              this.openValueSetModal('UOM', index);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectFmsProject(item) {
      this.selectedFmsProject = {
        id: item.fms_prj_id,
        name: item.fms_prj_name
      };
      this.interestForm.fms_prj_id = item.fms_prj_id;
      this.showFmsPrjModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'FMS_COMP_GROUP_TABLE') {
        this.fmsCompGroup.id = item.fms_comp_group_id;
        this.fmsCompGroup.name = item.fms_comp_group_name;
        this.interestForm.fms_comp_group_id = item.fms_comp_group_id;
      } else if (this.vsetCode === 'INTEREST_RATE_TYPE') {
        this.detaliList[this.interestDtlIndex].interest_type =
          item.value_meaning;
        this.detaliList[this.interestDtlIndex].interest_type_vset =
          item.value_code;
      } else if (this.vsetCode === 'UOM') {
        this.detaliList[this.interestDtlIndex].frequency_uom =
          item.value_meaning;
        this.detaliList[this.interestDtlIndex].frequency_uom_vset =
          item.value_code;
      }
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.interestDtlIndex = index;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.parent_value_set_id = '2';
      }
      if (this.uomClassObj) {
        this.parent_value_set_id = this.uomClassObj.value_set_dtl_id;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    addNewRow() {
      if (this.editMode) {
        this.detaliList.push({
          percentage_rate: null,
          interest_rate_dtl_id: 0,
          delay_days_from: null,
          frequency_uom_vset: null,
          interest_type_vset: null,
          frequency_uom: null,
          interest_type: null,
          delay_days_to: 0,
          start_date: null,
          start_date_end_date: null,
          frequency: null,
          end_date: null
        });
      }
    },
    removeRow(index) {
      this.detaliList.splice(index, 1);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    openFmsPrjModal(flag) {
      this.showFmsPrjModal = flag;
    }
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
    this.unsubscribe();
  }
};
