import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import addEditFmsInterestRate from './addEditFmsInterestRate';
import fmsProjMaster from '../../../fms/unitInventory/fmsProject';
export default {
  name: 'fmsInterestRate',
  components: {
    ModelSelect,
    DatePicker,
    addEditFmsInterestRate,
    fmsProjMaster
  },
  watch: {
    currentPage: function() {
      this.getInterestRateList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getInterestRateList();
    }
  },
  data() {
    return {
      showFmsPrjModal: false,
      intRateHdrId: null,
      parent_value_set_id: null,
      payload: {},
      selectedCompMasterData: null,
      showIntRateModal: false,
      loader: false,
      unsubscribe: null,
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      componentMaster: null,
      vsetCode: null,
      showValueSetModal: false,
      setTimeVsetCode: null,
      selectedFmsProject: {
        id: null,
        name: null
      },
      approvalStatus: {
        code: null,
        name: null
      },
      component_code: null,
      component_name: null,
      display_name: null,
      start_date: null,
      end_date: null,
      penaltyTermList: [],
      fmsInterestRateData: [],
      interestRateFields: [
        {
          key: 'fms_prj_name'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'version'
        }
      ]
    };
  },
  mounted() {
    this.getInterestRateList();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.selectedCompMasterData = null;
          this.intRateHdrId = null;
          this.showIntRateModal = true;
        }
        if (actionName === 'download') {
          this.loader = true;
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'masterData/getInterestRateList',
            'component-master',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getInterestRateList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        fms_prj_id: this.selectedFmsProject.id,
        approval_status: this.approvalStatus.code
      };
      this.loader = true;
      this.$store
        .dispatch('masterData/getInterestRateList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.fmsInterestRateData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.selectedFmsProject = {
          id: item.proj_id,
          name: item.fms_prj_name
        };
      } else if (this.vsetCode === 'STATUS_LIST') {
        this.approvalStatus.code = item.value_code;
        this.approvalStatus.name = item.status;
      }
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.parent_value_set_id = '2';
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    showhideInterestModal(flag) {
      this.showIntRateModal = flag;
    },
    rowSelected(item) {
      this.intRateHdrId = item.interest_rate_hdr_id;
      this.showIntRateModal = true;
    },
    clearSearch() {
      this.selectedFmsProject = {
        id: null,
        name: null
      };
      this.approvalStatus = {
        code: null,
        name: null
      };
      this.getInterestRateList();
    },
    selectFmsProject(item) {
      this.selectedFmsProject = {
        id: item.fms_prj_id,
        name: item.fms_prj_name
      };
      this.showFmsPrjModal = false;
    },
    openFmsPrjModal(flag) {
      this.showFmsPrjModal = flag;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
